import Grid from '@mui/material/Grid';
import { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { AnyAction } from 'redux';
import {
  changePendingInterfacesFilterCountry,
  changePendingInterfacesFilterEndBusinessDate,
  changePendingInterfacesFilterStartBusinessDate,
  changePendingInterfacesFilterStore,
  reportPendingInterfacesSalesLoadingSelector,
  findPendingInterfacesSalesRequested,
  masterDataSelector,
  reportPendingInterfacesFilterEndBusinessDateSelector,
  reportPendingInterfacesFilterStartBusinessDateSelector,
} from '../../state';

import { ReportFilterActions, ReportFilters } from '../ReportFilters';

import { CountryFilter, StoreFilter, BusinessDateRangeFilter } from './filters';

import { dateResponseMap } from '../../helpers';
import { AppPermissions, MasterData, RemoteResource, Stores } from '../../types';
import { PermissionsProvider } from '../Permissions';

const handleSearchParams = (
  dispatch: Dispatch<AnyAction>,
  searchParams: URLSearchParams,
  masterData: RemoteResource<MasterData | undefined>
) => {
  const country = searchParams.get('country');
  const stores = searchParams.get('stores')?.split(',');
  const startDate = dateResponseMap(searchParams.get('startDate')) as Date;
  const endDate = dateResponseMap(searchParams.get('endDate')) as Date;

  if (country && startDate && endDate) {
    dispatch(changePendingInterfacesFilterStartBusinessDate(startDate));
    dispatch(changePendingInterfacesFilterEndBusinessDate(endDate));
    if (country) {
      const masterDataCountries = masterData.data?.countries || [];
      const filterCountry = masterDataCountries.find(i => i.countryCode === country);
      if (filterCountry) dispatch(changePendingInterfacesFilterCountry(country));
    }
    if (stores) {
      const masterDataStores = masterData.data?.stores || [];
      const filterStores = stores.map(storeAcronym =>
        masterDataStores.find(i => i.countryCode === country && i.storeAcronym === storeAcronym)
      );
      dispatch(changePendingInterfacesFilterStore(filterStores as Stores[]));
    }
  }
};

const PendingInterfacesSearch = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const masterData = useSelector(masterDataSelector);
  const startBusinessDate = useSelector(reportPendingInterfacesFilterStartBusinessDateSelector);
  const endBusinessDate = useSelector(reportPendingInterfacesFilterEndBusinessDateSelector);
  const applyDisabled = !(startBusinessDate.accept && endBusinessDate.accept);

  useEffect(() => {
    handleSearchParams(dispatch, searchParams, masterData);
  }, [dispatch, masterData, searchParams]);

  return (
    <ReportFilters
      loadingSelector={reportPendingInterfacesSalesLoadingSelector}
      applyAction={findPendingInterfacesSalesRequested}
    >
      <PermissionsProvider resourcePermissions={[AppPermissions.SALES_TOTALS]}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={4} lg={2} xl={2}>
            <CountryFilter fullWidth />
          </Grid>
          <Grid item xs={12} md={4} lg={2} xl={2}>
            <StoreFilter />
          </Grid>
          <Grid item xs={12} md={5} lg={4} xl={3}>
            <BusinessDateRangeFilter spacing={1} />
          </Grid>
          <Grid item xs={12} md lg xl>
            <ReportFilterActions applyDisabled={applyDisabled} />
          </Grid>
        </Grid>
      </PermissionsProvider>
    </ReportFilters>
  );
};

export default PendingInterfacesSearch;
