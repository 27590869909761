import { ClosingDetailGroup, ClosingViewMode, Country, MissingCauses } from '../../types';
import { DataGridHandler } from '../DataGridFlexSales';
import CausesButton from './CausesButton';

export interface ClosingDataGridContext {
  viewMode: ClosingViewMode;
  countries: Country[];
  t: (key: string | string[]) => string;
}

const initialContext: ClosingDataGridContext = {
  viewMode: ClosingViewMode.CONSOLIDATED,
  countries: [],
  t: () => '',
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<ClosingDetailGroup, ClosingDataGridContext>(
    initialContext,
    locale
  );

  handler.addColumn({
    name: 'cause',
    type: 'string',
    title: ({ t }) => t('missing-closing-detail.columns.cause'),
    valueGetter: (row, { t }) =>
      row.cause ? t(`missing-closing-detail.causes.${row.cause}`) : undefined,
    width: { px: 450, pt: 400 },
  });

  handler.addColumn({
    accessor: 'country',
    type: 'string',
    title: ({ t }) => t('table.columns.country'),
  });

  handler.addColumn({
    accessor: 'store',
    type: 'string',
    title: ({ t }) => t(`table.columns.store`),
  });

  handler.addColumn({
    accessor: 'federativeEntity',
    type: 'string',
    available: context => context.countries.some(country => country.countryCode === 'BR'),
    title: ({ t }) => t('missing-closing-detail.columns.federativeEntity'),
  });

  handler.addColumn<string>({
    accessor: 'franchiseType',
    type: 'string',
    available: context => context.countries.some(country => country.countryCode === 'BR'),
    title: ({ t }) => t('missing-closing-detail.columns.franchiseType'),
  });

  handler.addColumn<string>({
    accessor: 'quantity',
    type: 'number',
    available: context => context.countries.some(country => country.countryCode === 'BR'),
    title: ({ t }) => t('missing-closing-detail.columns.quantity'),
  });

  handler.addColumn<string>({
    accessor: 'dates',
    type: 'date',
    isDynamic: true,
    formatter: value => (value ? 'X' : ' '),
    renderCell: ({ value, row, context }) =>
      value ? (
        <CausesButton
          viewMode={context.viewMode}
          country={row.country}
          store={row.store}
          date={value as Date}
          cause={row.cause as MissingCauses}
        />
      ) : undefined,
    title: ({ value }) => value,
  });

  return handler;
};
