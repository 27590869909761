import styled from '@emotion/styled';
import CollectionsIcon from '@mui/icons-material/AccountBalance';
import AppsIcon from '@mui/icons-material/Apps';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BackspaceIcon from '@mui/icons-material/Backspace';
import BuildIcon from '@mui/icons-material/Build';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import MoneyIcon from '@mui/icons-material/CurrencyExchange';
import Dashboard from '@mui/icons-material/Dashboard';
import DifferenceIcon from '@mui/icons-material/Difference';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import IcecreamIcon from '@mui/icons-material/Icecream';
import LocalConvenienceStoreIcon from '@mui/icons-material/LocalConvenienceStore';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import EntriesIcon from '@mui/icons-material/NoteAlt';
import PaidIcon from '@mui/icons-material/Paid';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReplayIcon from '@mui/icons-material/Replay';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import RuleIcon from '@mui/icons-material/Rule';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NestedList } from '../../components/NestedList';
import routes from '../../config/routes';
import { hasPermissions } from '../../helpers';
import { userPermissionsDataSelector } from '../../state';
import ListExternalLinkButton from './ListExternalLinkButton';

const othersRoutesNav = [
  {
    route: routes.protectedNestedRoutes.dashboard.index,
    icon: <Dashboard />,
    tranlationKey: 'Dashboard',
  },
];

const reportsRoutesNav = [
  {
    route: routes.protectedNestedRoutes.accountingEntries.index,
    icon: <EntriesIcon />,
    tranlationKey: 'accountingEntries.button',
    noLink: true,
    children: [
      {
        route: routes.protectedNestedRoutes.accountingEntries.index,
        icon: <MoneyIcon />,
        tranlationKey: 'accountingEntries.title',
      },
      {
        route: routes.protectedNestedRoutes.companyCodes.index,
        icon: <LocalConvenienceStoreIcon />,
        tranlationKey: 'companyCodes.titles.menu',
      },
      {
        route: routes.protectedNestedRoutes.accountingParameters.index,
        icon: <BuildIcon />,
        tranlationKey: 'accountingParameters.title',
      },
      {
        route: routes.protectedNestedRoutes.monthClosing.index,
        icon: <EventAvailableIcon />,
        tranlationKey: 'monthClosing.title',
      },
    ],
  },
  {
    route: routes.protectedRoutes.help,
    icon: <PaidIcon />,
    tranlationKey: 'sale-totals.button',
    noLink: true,
    children: [
      {
        route: routes.protectedRoutes.saleTotals,
        icon: <AttachMoneyIcon />,
        tranlationKey: 'sale-totals.title',
      },
      { route: routes.protectedRoutes.gcs, icon: <ReceiptIcon />, tranlationKey: 'gcs.title' },
      {
        route: routes.protectedRoutes.gcsByProduct,
        icon: <LunchDiningIcon />,
        tranlationKey: 'gcs-by-product.title',
      },
      {
        route: routes.protectedRoutes.salesByProduct,
        icon: <FastfoodIcon />,
        tranlationKey: 'sale-by-product.title',
      },
      {
        route: routes.protectedRoutes.reportTender,
        icon: <RequestPageIcon />,
        tranlationKey: 'report-tender.title',
      },
      {
        route: routes.protectedRoutes.thirdPartySalesByItems,
        icon: <IcecreamIcon />,
        tranlationKey: 'thirdPartySalesByItems.title',
      },
      {
        route: routes.protectedRoutes.cancellations,
        icon: <BackspaceIcon />,
        tranlationKey: 'cancellations.title',
      },
      {
        route: routes.protectedRoutes.oakBrook,
        icon: <SimCardDownloadIcon />,
        tranlationKey: 'oak-brook.title',
      },
    ],
  },
  {
    route: routes.protectedRoutes.profile,
    icon: <CollectionsIcon />,
    tranlationKey: 'toolbar.labels.collections',
    noLink: true,
    children: [
      {
        route: routes.protectedRoutes.reliefs,
        icon: <PointOfSaleIcon />,
        tranlationKey: 'reliefs.title',
      },
      {
        route: routes.protectedRoutes.pettyCashDifference,
        icon: <DifferenceIcon />,
        tranlationKey: 'petty-cash-difference.title',
      },
    ],
  },
  {
    route: routes.protectedNestedRoutes.missingClosingDetail.index,
    icon: <RuleIcon />,
    tranlationKey: 'toolbar.labels.incorporation',
    noLink: true,
    children: [
      {
        route: routes.protectedNestedRoutes.missingClosingDetail.index,
        icon: <SummarizeIcon />,
        tranlationKey: 'missing-closing-detail.title',
      },
      {
        route: routes.protectedNestedRoutes.reprocess.index,
        icon: <ReplayIcon />,
        tranlationKey: 'reprocess.title',
      },
      {
        route: routes.protectedNestedRoutes.mastersImport.index,
        icon: <CloudSyncIcon />,
        tranlationKey: 'masters-import.title',
      },
      {
        route: routes.protectedRoutes.pendingInterfaces,
        icon: <SummarizeIcon />,
        tranlationKey: 'pending-interfaces.title',
      },
    ],
  },
  // {
  //   route: routes.protectedRoutes.salesJournal,
  //   icon: <BookIcon />,
  //   tranlationKey: 'sales-journal.title',
  // },
];

const LastList = styled(List)`
  flex-grow: 1;
`;

interface SideBarNavProps {
  isSidebarOpen: boolean;
}

const SidebarNav = ({ isSidebarOpen }: SideBarNavProps) => {
  const { t } = useTranslation();
  const permissions = useSelector(userPermissionsDataSelector).map(i => i.permission);
  const dashboardRoutesAccess = useMemo(
    () => othersRoutesNav.filter(i => hasPermissions(permissions, i.route.permissions)),
    [permissions]
  );
  const reportsRoutesAccess = useMemo(
    () =>
      reportsRoutesNav
        .map(route => ({
          ...route,
          children: route.children.filter(child =>
            hasPermissions(permissions, child.route.permissions)
          ),
        }))
        .filter(route => route.children.length > 0),
    [permissions]
  );

  return (
    <>
      <NestedList data={dashboardRoutesAccess} />
      <Divider />
      <NestedList data={reportsRoutesAccess} subheader={t('layout.drawer.reports')} />
      <Divider />
      <LastList>
        <ListExternalLinkButton
          icon={<AppsIcon />}
          to={process.env.FLEX_TILES_URL || '/'}
          translationKey="Flex Tiles"
          showTooltip={!isSidebarOpen}
        />
      </LastList>
    </>
  );
};

export default SidebarNav;
