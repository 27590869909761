import Grid from '@mui/material/Grid';
import { Dispatch, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { AnyAction } from 'redux';
import {
  changeClosingFilterCountry,
  changeClosingFilterEndBusinessDate,
  changeClosingFilterStartBusinessDate,
  changeClosingFilterViewMode,
  changeFilterStore,
  findClosingCollectionsRequested,
  findClosingSalesRequested,
  masterDataSelector,
  reportClosingCollectionsLoadingSelector,
  reportClosingFilterCountrySelector,
  reportClosingFilterEndBusinessDateSelector,
  reportClosingFilterStartBusinessDateSelector,
  reportClosingFilterViewModeSelector,
  reportClosingSalesLoadingSelector,
} from '../../state';

import { ReportFilterActions, ReportFilters } from '../ReportFilters';

import { dateResponseMap } from '../../helpers';
import { AppPermissions, ClosingViewMode, MasterData, RemoteResource, Stores } from '../../types';
import { PermissionsProvider } from '../Permissions';
import {
  BusinessDateRangeFilter,
  CauseTypeFilter,
  CountryFilter,
  FederativeEntityFilter,
  FranchiseTypeFilter,
  StoreFilter,
  ViewModeFilter,
} from './filters';

const handleSearchParams = (
  dispatch: Dispatch<AnyAction>,
  searchParams: URLSearchParams,
  masterData: RemoteResource<MasterData | undefined>
) => {
  const viewMode = (searchParams.get('viewMode') || 'consolidated') as ClosingViewMode;
  const country = searchParams.get('country');
  const stores = searchParams.get('stores')?.split(',');
  const startDate = dateResponseMap(searchParams.get('startDate')) as Date;
  const endDate = dateResponseMap(searchParams.get('endDate')) as Date;

  if (country && startDate && endDate) {
    dispatch(changeClosingFilterViewMode(viewMode));
    dispatch(changeClosingFilterStartBusinessDate(startDate));
    dispatch(changeClosingFilterEndBusinessDate(endDate));
    if (country) {
      const masterDataCountries = masterData.data?.countries || [];
      const filterCountry = masterDataCountries.find(i => i.countryCode === country);
      if (filterCountry) dispatch(changeClosingFilterCountry([filterCountry]));
    }
    if (stores) {
      const masterDataStores = masterData.data?.stores || [];
      const filterStores = stores.map(storeAcronym =>
        masterDataStores.find(i => i.countryCode === country && i.storeAcronym === storeAcronym)
      );
      dispatch(changeFilterStore(filterStores as Stores[]));
    }
    if ([ClosingViewMode.SALES, ClosingViewMode.CONSOLIDATED].includes(viewMode)) {
      dispatch(findClosingSalesRequested(startDate, endDate));
    }
    if ([ClosingViewMode.COLLECTIONS, ClosingViewMode.CONSOLIDATED].includes(viewMode)) {
      dispatch(findClosingCollectionsRequested(startDate, endDate));
    }
  }
};

const ClosingSearch = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const masterData = useSelector(masterDataSelector);
  const viewMode = useSelector(reportClosingFilterViewModeSelector);
  const countries = useSelector(reportClosingFilterCountrySelector);
  const startBusinessDate = useSelector(reportClosingFilterStartBusinessDateSelector);
  const endBusinessDate = useSelector(reportClosingFilterEndBusinessDateSelector);
  const applyDisabled = !(startBusinessDate.accept && endBusinessDate.accept);

  const showBrGrid = countries.length === 1 && countries[0].countryCode === 'BR';

  useEffect(() => {
    handleSearchParams(dispatch, searchParams, masterData);
  }, [dispatch, masterData, searchParams]);

  const applyAction = useCallback(() => {
    if (viewMode === ClosingViewMode.SALES) {
      return [findClosingSalesRequested()];
    }
    if (viewMode === ClosingViewMode.COLLECTIONS) {
      return [findClosingCollectionsRequested()];
    }
    return [findClosingSalesRequested(), findClosingCollectionsRequested()];
  }, [viewMode]);

  const loadingSelector = () => {
    if (viewMode === ClosingViewMode.SALES) {
      return reportClosingSalesLoadingSelector;
    }
    if (viewMode === ClosingViewMode.COLLECTIONS) {
      return reportClosingCollectionsLoadingSelector;
    }
    return reportClosingSalesLoadingSelector || reportClosingCollectionsLoadingSelector;
  };

  return (
    <ReportFilters loadingSelector={loadingSelector()} applyAction={applyAction}>
      <PermissionsProvider resourcePermissions={[AppPermissions.MISSING_CLOSING_DETAIL]}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={4} lg={2} xl={2}>
            <ViewModeFilter fullWidth />
          </Grid>
          <Grid item xs={12} md={4} lg={2} xl={2}>
            <CountryFilter fullWidth />
          </Grid>
          <Grid item xs={12} md={4} lg={2} xl={2}>
            <StoreFilter />
          </Grid>
          {showBrGrid && (
            <>
              <Grid item xs={12} md={4} lg={2} xl={2}>
                <FederativeEntityFilter fullWidth />
              </Grid>
              <Grid item xs={12} md={4} lg={2} xl={2}>
                <FranchiseTypeFilter fullWidth />
              </Grid>
            </>
          )}
          <Grid item xs={12} md={5} lg={4} xl={3}>
            <BusinessDateRangeFilter spacing={1} />
          </Grid>
          <Grid item xs={12} md={4} lg={2} xl={2}>
            <CauseTypeFilter />
          </Grid>
          <Grid item xs={12} md lg xl>
            <ReportFilterActions applyDisabled={applyDisabled} />
          </Grid>
        </Grid>
      </PermissionsProvider>
    </ReportFilters>
  );
};

export default ClosingSearch;
