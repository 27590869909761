import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';
import { PendingInterfacesDataGrid, PendingInterfacesSearch } from '../components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  > :first-of-type {
    margin-bottom: ${props => props.theme.spacing(3)};
  }
`;

const PendingInterfaces = () => (
  <>
    <Container>
      <PendingInterfacesSearch />
      <PendingInterfacesDataGrid />
    </Container>
    <Outlet />
  </>
);

export default PendingInterfaces;
