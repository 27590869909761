import Grid from '@mui/material/Grid';
import {
  changeSaleProductFilterGroupBy,
  reportFilterSalesProductGroupBySelector,
  reportSalesByProductLoadingSelector,
  findSalesByProductRequested,
} from '../state';

import {
  ReportFilterActions,
  ReportFilters,
  ReportFiltersProps,
  CountryFilter,
  HourRangeFilter,
  TimeBandFilter,
  PosFilter,
  SegmentsFilter,
  StoreFilter,
  SalesTypesFilter,
  OperationDateRangeFilter,
  AccountingDateRangeFilter,
  BusinessDateRangeFilter,
  PluFilter,
} from './ReportFilters';

import { AppPermissions, ReportFilter, ReportFilterGroupBy } from '../types';
import { PermissionsProvider } from './Permissions';

const groupByConfig: ReportFiltersProps<ReportFilterGroupBy>['groupByConfig'] = {
  options: {
    countryAcronym: true,
    storeAcronym: false,
    businessDate: false,
    accountingDate: false,
    operationDate: false,
    posType: false,
    saleType: false,
    posNum: false,
    timeRange: false,
    timeBand: false,
    plu: false,
    regionalMgmtId: false,
    regionId: false,
    mgmtId: false,
    supervisionId: false,
    agreementId: false,
    companyId: false,
  },
  action: changeSaleProductFilterGroupBy,
  selector: reportFilterSalesProductGroupBySelector,
};

const dialogFilters = (
  <>
    <BusinessDateRangeFilter />
    <OperationDateRangeFilter />
  </>
);

const onedriveAction = () => findSalesByProductRequested(true);

const dialogFiltersApplied: Array<keyof ReportFilter> = [
  'startOperationDate',
  'endOperationDate',
  'startBusinessDate',
  'endBusinessDate',
];

const SalesByProductSearch = () => (
  <ReportFilters
    groupByConfig={groupByConfig}
    loadingSelector={reportSalesByProductLoadingSelector}
    applyAction={findSalesByProductRequested}
    dialogFilters={dialogFilters}
    dialogFiltersApplied={dialogFiltersApplied}
    onedriveAction={onedriveAction}
    showOpStructureFilters
  >
    <PermissionsProvider resourcePermissions={[AppPermissions.SALES_TOTALS]}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <CountryFilter fullWidth />
        </Grid>
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <StoreFilter />
        </Grid>
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <PosFilter />
        </Grid>
        <Grid item xs={12} md={3} lg={6} xl={2}>
          <SalesTypesFilter />
        </Grid>
        <Grid item xs={12} md={4} lg={2} xl={2}>
          <SegmentsFilter />
        </Grid>
        <Grid item xs={12} md={4} lg={2} xl={2}>
          <TimeBandFilter />
        </Grid>
        <Grid item xs={12} md={4} lg={2} xl={2}>
          <HourRangeFilter />
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <AccountingDateRangeFilter spacing={1} />
        </Grid>
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <PluFilter />
        </Grid>
        <Grid item xs={12} md lg xl>
          <ReportFilterActions />
        </Grid>
      </Grid>
    </PermissionsProvider>
  </ReportFilters>
);

export default SalesByProductSearch;
